<template>
    <div class="activity">
        <el-container>
            <el-main class="actCont">
                <div class="goApply">
                    <el-button type="primary" v-if="detail.activityStatus==4" @click="isApply=true">去报名</el-button>
                </div>
                <el-dialog :visible.sync="isApply" width="300px">
                    <div style="text-align: center;">
                        <p style="font-size:20px;">关注公众号去报名</p>
                        <img src="~@/assets/img/code.jpg" alt="" style="width:200px;height:200px;">
                    </div>
                    <div slot="footer" class="dialog-footer">
                        <el-button size="mini" type="primary" @click="isApply = false">关 闭</el-button>
                    </div>
                </el-dialog>
                <div class="activityBanner">
                    <img :src="headUrl(detail.showImg)" alt="">
                </div>
                <div class="act_head">
                    <div>
                        {{detail.activityName}}
                    </div>
                    <p></p>
                    <p><span>活动时间：{{detail.activityTimeStr}}</span><span>报名截止：{{detail.activityEndTimeStr}}</span>活动状态：{{statusTxtAry[detail.activityStatus]}}  </p>
                </div>
                <div class="activity_intro">
                    <h5>活动简介</h5>
                    <div>{{detail.activityDesc}}</div>
                    <h5>活动详情</h5>
                    <div class="act_intro">
                        <div v-for="item in intro">
                            <img :src="setUrl(item.cont)" alt="" v-if="item.type=='img'">
                            <p v-else>{{item.cont}}</p>
                        </div>
                    </div>
                </div>
                <div class="activity_cont">
                    <h5>活动发起者</h5>
                    <div class="mainUser">
                        <img v-if="detail.commentUserPhoto != null" :src="headUrl(detail.activityUserPhoto)" alt="">
                        <p>
                            <strong>活动发起者</strong>
                            <span>{{detail.activityUserName}}</span>
                        </p>
                    </div>
                    <div class="contDec" v-if="info!=null">
                        <p>{{info.content}}</p>
                        <img v-for="item in infoImg" :src="setUrl(item)" alt="">
                    </div>
                </div>
                <div class="leave" v-if="commentList.length!=0">
                    <h5></h5>
                    <div class="leaveCont">
                        <div class="leaveMin" v-for="item in commentList">
                            <div class="minCont">
                                <img v-if="item.commentUserPhoto != null" :src="headUrl(item.commentUserPhoto)" alt="" class="userImg">
                                <div class="right">
                                    <h6>{{item.commentUser}}</h6>
                                    <p>{{item.content}}</p>
                                    <div class="fun">
                                        <time>{{item.addTimeStr}}</time>
                                        <div class="commentImg">
                                            <img :src="splitArr(item.showImg)" alt="">
                                        </div>
                                        <!--<p>
                                            <span class="leaveNum">2</span>
                                            <span class="praiseNum">10</span>
                                        </p>-->
                                    </div>
                                    <!--<a href="javascript:;">展开</a>-->
                                </div>
                            </div>
                            <!--<div class="min_Min">
                                <div class="minCont">
                                    <img src="" alt="">
                                    <div class="right">
                                        <h6>我是新手</h6>
                                        <p>内容内容</p>
                                    </div>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </el-main>
            <el-aside class="act_aside" style="margin-left:20px;">
                <el-button class="back_btn" type="primary" @click="goList">返回</el-button>
                <div class="act_detail_user">
                    <img :src="headUrl(detail.activityUserPhoto)"/>
                    <p>{{detail.activityUserName}}</p>
                </div>
            </el-aside>
        </el-container>
    </div>
</template>

<script>
    import fetch from '@/services/service'
    export default {
        name: "activity-detail",
        data() {
            return {
                activityId:"",
                detail:[],
                intro:"",
                info:"",
                infoImg:"",
                commentList:"",
                isApply:false,
                statusTxtAry:["未发布","未开始","已结束","下架(系统强制)","活动中"],
            }
        },
        created() {
            this.activityId = this.$route.params.id
            this.getDetail();//活动
            this.getInfo();//活动详情
            this.getComment();//评论列表
        },
        methods:{
            setUrl(url) {
                if(url){
                    if(url.indexOf("http")==0){
                        return url;
                    }else{
                        return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
                    }
                }
            },
            headUrl(url){
                if(url){
                    if(url.indexOf("http")==0){
                        return url;
                    }else{
                        return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
                    }
                }else{
                    return require("../../assets/img/head.png");
                }
            },
            goList(){
                window.close();
                /*this.$router.push({
                    path: "/activity",
                })*/
            },
            getDetail() {
                let sdata = {
                    activityId: this.activityId,
                }
                fetch
                    .post('/officialWebsiteMgr/resource/activity/detail',sdata)
                    .then((res) => {
                        if (res.code == 0) {
                            this.detail = res.data;
                            this.intro = JSON.parse(this.detail.activityContent);
                        }
                    })
            },
            getInfo() {
                let sdata = {
                    activityId: this.activityId,
                }
                fetch
                    .post('/officialWebsiteMgr/resource/activity/actCrtCmtDetal',sdata)
                    .then((res) => {
                        if (res.code == 0) {
                            this.info = res.data;
                            if(this.info!=null){
                                this.infoImg = this.info.showImg.split(",");
                            }
                        }
                    })
            },
            getComment() {
                let sdata = {
                    activityId: this.activityId,
                }
                fetch
                    .post('/officialWebsiteMgr/resource/activity/commentList',sdata)
                    .then((res) => {
                        if (res.code == 0) {
                            this.commentList = res.data.list;
                        }
                    })
            },
            splitArr(str) {
                if(str!=""){
                    var arr = str.split(",");
                    if(arr.length==1){
                        return this.setUrl(str);
                    }else{
                        $.each(arr,function(i,item){
                            return this.setUrl(item);
                        })
                    }
                }

            },
        }
    }
</script>

<style scoped>
.activity{
    width:1200px;
    margin:20px auto;
    display: flex;
}
.actCont{
    border-radius: 3px;
    box-shadow: 0 0 1px #000;
    padding:20px;
    position: relative;
    background:#fff;
    width:880px;
    overflow: hidden;
}
.act_detail_user{
    border: 1px solid #c5c5c5;
    background-color: #fff;
    padding: 20px;
    text-align: center;
}
.act_detail_user img{
    height: 90px;
    width: 90px;
    display: block;
    margin: 20px auto 0;
}
.act_detail_user p{
    font-size: 16px;
    color: #333;
    margin-top: 10px;
}
.activity .activityBanner{
    margin-bottom:20px;
    text-align: center;
    background:#fff;
}
.act_head{
    font-size: 18px;
    text-align: center;
    color: #049aee;
}
.act_head p{
    font-size: 16px;
    color:#999;
    margin:20px 0;
}
.act_head p span{
    margin-right:20px;
}
.activity_intro h5,.activity_cont h5{
    border-left: solid 4px #599dff;
    font-size: 18px;
    padding-left:10px;
}
.activity_intro>div{
    margin:10px 0;
}
.contDec{
    padding:30px 0;
}
.act_intro>div{
    margin-bottom: 10px;
}
.act_intro img{
    display: block;
    margin:10px auto;
}
.contDec img{
    width:75px;
    height:75px;
    border-radius: 40px;
    margin-right:10px;
}
.act_intro p{
    line-height:24px;
}
.leave{
    padding:20px 0;
}
.leave h5{
    background:url("~@/assets/img/leave.jpg")no-repeat center center;
    width:100%;
    height:52px;
}
.leaveCont{
    padding:0 30px;
}
.leaveMin{
    margin-bottom: 20px;
    border-bottom:solid 1px #888;
    padding-bottom: 20px;
}
.minCont{
    display: flex;
    display: -webkit-flex;
}
.leaveMin .userImg{
    width:45px;
    height:45px;
    border-radius: 25px;
}
.right{
    margin-left:26px;
}
.right h6{
    font-size: 22px;
    font-weight: normal;
    color:#7979ea;
}
.right>p{
    font-size: 22px;
    margin:10px 0;
}
.commentImg{
    margin-top:10px;
}
.fun p{
    color:#676767;
}
.fun time{
    display: block;
    width:490px;
}
.right>a{
    display: block;
    font-size: 18px;
    color:#32a1fb;
    margin-top:20px;
    text-decoration: none;
}
.leaveNum{
    background:url("~@/assets/img/art_dis.png")no-repeat right center;
    padding-right:25px;
    margin-right:22px;
}
.praiseNum{
    background:url("~@/assets/img/praise_off.png")no-repeat right center;
    padding-right:25px;
}
.min_Min{
    margin:20px 0 0 68px;
}
.mainUser{
    padding:20px 0 0 30px;
    display: flex;
}
.mainUser img{
    width:75px;
    height:75px;
    border-radius: 40px;
}
.mainUser p{
    margin-left:10px;
}
.mainUser strong{
    border: solid 2px #4ba5f3;
    border-radius: 10px 15px 15px 0;
    display: block;
    width: 90px;
    height: 20px;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
}
.mainUser span{
    font-size: 20px;
    color:#333;
}
    .goApply{
        position: absolute;
        right:10px;
        top:10px;
    }
</style>